<template>
  <div class="assessWrap">
    <el-dialog width="700px" :visible.sync="dialogVisible" title="课程评价">
      <div class="qInfo">
        <p class="infoTitle">2021教师招聘备考早行动——速记巧思第三季</p>
        <div class="infoStar">
          <span class="starText">留下你宝贵的打分</span>
          <i
            class="iconfont icon-grayStar"
            :class="nowStar >= item ? 'on' : ''"
            v-for="(item, index) in 5"
            :key="index"
            @click="evalStar(item)"
          ></i>
        </div>
        <div class="infoInput">
          <el-input
            type="textarea"
            placeholder="输入评价内容"
            v-model="assessValue"
            maxlength="200"
            show-word-limit
            :rows="5"
            class="inputWrap"
          >
          </el-input>
        </div>
      </div>
      <div class="btnWrap">
        <el-button type="primary" round @click="dialogVisible = false"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      nowStar: "",
      assessValue: "",
    };
  },
  mounted() {},
  methods: {
    openInit() {
      this.dialogVisible = true;
    },
    evalStar(item) {
      this.nowStar = item;
    },
  },
};
</script>

<style scoped lang="scss">
.assessWrap {
  /deep/.el-dialog {
    border-radius: 20px;
    .el-dialog__header {
      padding: 25px 30px;
      .el-dialog__title {
        font-weight: bold;
        font-size: 18px;
      }
      i {
        font-size: 20px;
      }
    }
    .el-dialog__body {
      padding: 0 30px 30px;
    }
  }
  .qInfo {
    margin-bottom: 30px;
    font-size: 16px;
    .infoTitle {
      font-weight: bold;
      margin-bottom: 20px;
      color: #333;
    }
    .infoStar {
      margin-bottom: 20px;
      span.starText {
        margin-right: 10px;
        color: #333;
      }
      i {
        margin-right: 5px;
        font-size: 20px;
        color: #dcdcdc;
        cursor: pointer;
      }
      .on {
        color: #ff9914;
      }
    }
    .infoInput {
      margin-bottom: 30px;
      .inputWrap {
        /deep/.el-textarea__inner {
          background: #f8f8f8;
          padding: 15px;
          font-size: 16px;
          border: none;
        }
        /deep/.el-input__count {
          background: #f8f8f8;
        }
      }
    }
  }
  .btnWrap {
    text-align: center;
  }
  .el-button.is-round {
    border-radius: 22px;
    padding: 13px 46px;
    font-size: 16px;
  }
}
</style>
