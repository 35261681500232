<template>
  <div class="feedbackWrap">
    <el-dialog width="700px" :visible.sync="dialogVisible" title="问题反馈">
      <p class="title">请选择您要反馈的问题，我们会尽快优化。</p>
      <el-row :gutter="10">
        <el-col :span="8" v-for="(item, index) in feedbackList" :key="index">
          <div
            class="itemWrap"
            @click="changeItem(item)"
            :class="chooseItem == item.id ? 'chooseItemWrap' : ''"
          >
            {{ item.name }}
          </div>
        </el-col>
      </el-row>
      <div class="btnWrap">
        <el-button type="primary" round @click="dialogVisible = false"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      feedbackList: [
        {
          id: 1,
          name: "无法播放",
        },
        {
          id: 2,
          name: "播放卡顿",
        },
        {
          id: 3,
          name: "音画不同步",
        },
        {
          id: 4,
          name: "视频没有画面",
        },
        {
          id: 5,
          name: "视频没有声音",
        },
        {
          id: 6,
          name: "拖动进度条失败",
        },
        {
          id: 7,
          name: "其他",
        },
      ],
      chooseItem: "",
    };
  },
  mounted() {},
  methods: {
    openInit() {
      this.dialogVisible = true;
    },
    changeItem(item) {
      this.chooseItem = item.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feedbackWrap {
  /deep/.el-dialog {
    border-radius: 20px;
    .el-dialog__header {
      padding: 25px 30px 10px;
      .el-dialog__title {
        font-weight: bold;
        font-size: 18px;
      }
      i {
        font-size: 20px;
      }
    }
    .el-dialog__body {
      padding: 0 30px 40px;
    }
  }
  .title {
    color: #999;
    margin-bottom: 35px;
  }
  .itemWrap {
    border-radius: 22px;
    height: 42px;
    line-height: 42px;
    border: 1px solid #f4f4f4;
    background: #f4f4f4;
    color: #333;
    text-align: center;
    font-size: 16px;

    margin-bottom: 15px;
    cursor: pointer;
    &:hover {
      color: #507fff;
    }
  }
  .chooseItemWrap {
    border: 1px solid #507fff;
    background: #edf3ff;
    color: #507fff;
  }
  .btnWrap {
    margin-top: 20px;
    text-align: center;
  }
  .el-button.is-round {
    border-radius: 22px;
    padding: 13px 46px;
    font-size: 16px;
  }
}
</style>
